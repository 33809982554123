import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Artist from "../components/artist"
import Painting from "../components/painting"
import loadable from "@loadable/component"

const CassattComponent = loadable(() => import("../components/cassattComponent"))

const Cassatt = ({ data }) => {
  return (

    <Layout>
      <SEO
        title="Mary Cassatt"
        description="The Life and Paintings"
        image="/logo.png"
        pathname="/cassatt"

      />

      <Artist
        bubble2={{ "--bubble2": "#facc2d" }}
        name={"Mary Cassatt"}
        dates={"(1844-1926)"}
        descrip={"Mary Cassatt was an American painter who spent most of her life in France. She was part of a group of artists in Paris known as Impressionists. Cassatt’s most-famous paintings focus on mothers caring for small children. "}
      />

      <Painting
        leftPanel={{ backgroundColor: "#103645" }}
        h1Title={"Self Portrait"}
        sectionDescription={"Cassatt was born in Pittsburgh, Pennsylvania and at age 15 began studying art.  Her parents were not excited about her becoming an artist.  They were very wealthy and expected her to become a well-educated wife and mother. "}
        paintingFixed={data.cassattselfportrait.childImageSharp.fixed}
        alt={"Mary Cassat Self Portrait"}
        rtPanel={{ backgroundColor: "#fadf81" }}
        // Mobile
        paintingFluid={data.cassattselfportraitmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#103645" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#26685c" }}
        h1Title={"Young Mother Sewing"}
        sectionDescription={"Instead, she decided to attend the Pennsylvania Academy of the Fine Arts without her parents' support.  At school she became frustrated because the male students and teachers refused to take her seriously.  She wasn’t allowed to paint live models and could paint only inanimate objects."}
        paintingFixed={data.youngmothersewing.childImageSharp.fixed}
        alt={"Young Mother Sewing"}
        rtPanel={{ backgroundColor: "#cacf83" }}
        paintingFluid={data.youngmothersewingmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#26685c" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#0e202e" }}
        h1Title={"Blue Armchair "}
        sectionDescription={"After four years, Cassatt dropped out of the academy and moved to Paris to study.  Women were not allowed to attend school, so instead she went to the Louvre Museum to study art there.  One of her pieces was accepted into a show at the Paris Salon, a very famous art exhibition that was difficult to get into. "}
        paintingFixed={data.bluearmchair.childImageSharp.fixed}
        alt={"Blue Armchair painting"}
        rtPanel={{ backgroundColor: "#cdd2ce" }}
        paintingFluid={data.bluearmchairmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#0e202e" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#dfb13c" }}
        h1Title={"Mother and Child"}
        sectionDescription={"Cassatt painted friends or relatives with their children in the Impressionist style. She tried to capture the effects of light and color rather than painting a detailed copy of figures. "}
        paintingFixed={data.motherandchild.childImageSharp.fixed}
        alt={"Mother and Child painting"}
        rtPanel={{ backgroundColor: "#919d6c" }}
        // Mobile
        paintingFluid={data.motherandchildmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#dfb13c" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#6e9ebd" }}
        h1Title={"Breakfast in Bed "}
        sectionDescription={"For years Cassatt had a difficult time supporting herself as a painter, so she put away her brushes and tried to pursue other ways to make money.  Fortunately, an archbishop in Italy hired her to paint copies of famous painting. "}
        paintingFixed={data.breakfastinbed.childImageSharp.fixed}
        alt={"Breakfast in Bed"}
        rtPanel={{ backgroundColor: "#d5d4d8" }}
        // Mobile
        paintingFluid={data.breakfastinbedmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#6e9ebd" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#671b1b" }}
        h1Title={"Woman with a Pearl Necklace"}
        sectionDescription={"Her career was rocky.  She struggled to sell her work and most male painters didn’t like her.  She could be critical of other painters’ work.  Edgar Degas, a French artist, was the exception.  He became her friend and helped her career grow. She was the only American artist to exhibit with the Impressionists in Paris. In turn, she helped Degas sell his work in the United States. "}
        paintingFixed={data.womanwithapearlnecklace.childImageSharp.fixed}
        alt={"Woman with a Pearl Necklace"}
        rtPanel={{ backgroundColor: "#f6d780" }}
        // Mobile
        paintingFluid={data.womanwithapearlnecklacemobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#671b1b" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#88728b" }}
        h1Title={"The Child’s Bath"}
        sectionDescription={"During the 1890’s, Cassatt’s paintings of women and children finally became very popular.  As her popularity grew, she was able to encourage her wealthy American friends and family to buy Impressionist paintings.  This really helped to push the acceptance of Impressionism in the United States."}
        paintingFixed={data.thechildsbath.childImageSharp.fixed}
        alt={"The Child’s Bath painting"}
        rtPanel={{ backgroundColor: "#58686c" }}
        // Mobile
        paintingFluid={data.thechildsbathmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#88728b" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#14263c" }}
        h1Title={"The Boating Party "}
        sectionDescription={"Around 1900 Cassatt started to go blind. By 1914 she stopped painting altogether.  Cassatt died on June 14, 1926, at Château de Beaufresne, near Paris."}
        paintingFixed={data.theboatingparty.childImageSharp.fixed}
        alt={"The Boating Party"}
        rtPanel={{ backgroundColor: "#9ebf9a" }}
        // Mobile
        paintingFluid={data.theboatingpartymobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#14263c" }}
      />
        <CassattComponent/>
    </Layout>
  )
}

export default Cassatt

export const query = graphql`
  {
 cassattselfportrait: file(relativePath: { eq: "paintings/cassatt/cassattselfportrait.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     cassattselfportraitmobile: file(relativePath: { eq: "paintings/cassatt/cassattselfportraitmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
     youngmothersewing: file(relativePath: { eq: "paintings/cassatt/youngmothersewing.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         youngmothersewingmobile: file(relativePath: { eq: "paintings/cassatt/youngmothersewingmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         bluearmchair: file(relativePath: { eq: "paintings/cassatt/bluearmchair.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         bluearmchairmobile: file(relativePath: { eq: "paintings/cassatt/bluearmchair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         motherandchild: file(relativePath: { eq: "paintings/cassatt/motherandchild.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         motherandchildmobile: file(relativePath: { eq: "paintings/cassatt/motherandchildmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         breakfastinbed: file(relativePath: { eq: "paintings/cassatt/breakfastinbed.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         breakfastinbedmobile: file(relativePath: { eq: "paintings/cassatt/breakfastinbed.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         womanwithapearlnecklace: file(relativePath: { eq: "paintings/cassatt/womanwithapearlnecklace.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         womanwithapearlnecklacemobile: file(relativePath: { eq: "paintings/cassatt/womanwithapearlnecklacemobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         thechildsbath: file(relativePath: { eq: "paintings/cassatt/thechildsbath.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         thechildsbathmobile: file(relativePath: { eq: "paintings/cassatt/thechildsbathmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         theboatingparty: file(relativePath: { eq: "paintings/cassatt/theboatingparty.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         theboatingpartymobile: file(relativePath: { eq: "paintings/cassatt/theboatingparty.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`